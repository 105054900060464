import styled from 'styled-components';
import Button from 'core/common/components/Button';
import Error from 'core/common/components/Error';
import Image from 'core/common/components/Image';
import Link from 'core/common/components/Link';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 52px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 66px 100px 80px;
  }
`;

export const Title = styled.h3`
  font-size: 26px;
  line-height: 145%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 32px;
    text-align: center;
  }
`;

export const Subtitle = styled.h5`
  margin-top: 16px;
  font-weight: 400;
  line-height: 135%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 18px;
    text-align: center;
  }
`;

export const StyledButton = styled(Button)`
  height: 50px;
  margin-top: 32px;
  margin-bottom: 24px;
  font-size: 18px;
`;

export const StyledTitle = styled(Title)`
  margin-top: 130px;
  margin-bottom: 16px;
  font-size: 22px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-top: 160px;
    font-size: 24px;
  }
`;

export const StyledImage = styled(Image)`
  margin-right: auto;
  margin-left: auto;
`;

export const AgreementOuterContainer = styled.div`
  position: relative;
  width: 330px;
  margin-right: auto;
  margin-left: auto;
  font-size: 12px;
`;

export const AgreementInnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

export const StyledError = styled(Error)`
  width: 100%;
  margin-top: 8px;
`;

export const StyledLink = styled(Link)`
  text-decoration: underline;
`;

export const Caption = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.typography600};
`;
