import styled from 'styled-components';

export const ContainerStyled = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  max-width: 1200px;
  margin-bottom: 13px;
  padding-top: 20px;
  text-align: center;
`;
