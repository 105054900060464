import styled from 'styled-components';
import Button from 'core/common/components/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 15px;
  background: ${(props) => props.theme.colors.themeBackgroundLight};

  @media ${(props) => props.theme.breakpoints.desktop} {
    margin-right: 30px;
    margin-left: 30px;
    padding: 40px;
    border-radius: 20px;
  }
`;

export const Title = styled.h3`
  margin-bottom: 32px;
  font-size: 22px;
  line-height: 145%;

  @media ${(props) => props.theme.breakpoints.desktop} {
    font-size: 24px;
  }
`;

export const List = styled.ul`
  margin: -6px;
`;

export const ListItem = styled.li`
  display: inline-block;
  width: 50%;
  padding: 6px;
`;

export const ListItemContent = styled.div`
  padding: 8px 10px 12px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.typography100};
  box-shadow: 0 8px 15px rgba(194, 194, 194, 0.25);
`;

export const ContentTitleWrapper = styled.p`
  display: flex;
  align-items: baseline;
  color: ${(props) => props.theme.colors.lunarViolet};
`;

export const Amount = styled.h2`
  margin-right: 4px;
  font-size: 24px;
  font-weight: 800;
  line-height: 33px;
`;

export const ContentTitle = styled.h4`
  font-size: 14px;
  line-height: 19px;
`;

export const ContentSubtitle = styled.span`
  font-size: 14px;
  line-height: 150%;
`;

export const StyledButton = styled(Button)`
  height: 50px;
  margin-top: 32px;
  font-size: 18px;
`;
