import { ReactComponent as Bulb } from 'core/common/assets/images/bulb.svg';
import { ReactComponent as Hearts } from 'core/common/assets/images/hearts.svg';
import { ReactComponent as Messages } from 'core/common/assets/images/messages.svg';
import { ReactComponent as Report } from 'core/common/assets/images/report.svg';
import {
  AdvantageSubtitle,
  AdvantageTitle,
  AdvantageWrapper,
  AdvantagesList,
  AdvantagesListItem,
  Container,
  ImageWrapper,
} from './styled';

export const advantages = [
  {
    image: <Messages />,
    title: 'One-on-One guidance',
    subtitle:
      'Get personalized advice from our expert advisors tailored to your astrological blueprint',
  },
  {
    image: <Report />,
    title: 'Personal astrology reading',
    subtitle:
      'Receive an in-depth reading highlighting your astrological profile and its influence on your ' +
      'life and relationships',
  },
  {
    image: <Bulb />,
    title: 'Deeper understanding of yourself',
    subtitle:
      'Explore the depths of your personality, strengths, weaknesses, and innate tendencies to foster ' +
      'self-growth and personal development',
  },
  {
    image: <Hearts />,
    title: 'Insightful relationship patterns',
    subtitle:
      'Illuminate the patterns governing your relationships and unravel the subtleties of your emotional ' +
      'and sexual needs.',
  },
];

const SectionAdvantages = () => {
  return (
    <Container>
      <AdvantagesList>
        {advantages.map((advantage) => (
          <AdvantagesListItem key={advantage.title}>
            <AdvantageWrapper>
              <ImageWrapper>{advantage.image}</ImageWrapper>
              <AdvantageTitle>{advantage.title}</AdvantageTitle>
              <AdvantageSubtitle>{advantage.subtitle}</AdvantageSubtitle>
            </AdvantageWrapper>
          </AdvantagesListItem>
        ))}
      </AdvantagesList>
    </Container>
  );
};

export default SectionAdvantages;
