import styled from 'styled-components';
import Rating from 'core/common/components/Rating';
import ReviewsReal from 'core/common/components/ReviewsReal';

export const Container = styled.div`
  max-width: 360px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 52px;
  padding-right: 15px;
  padding-left: 15px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding-top: 80px;
  }
`;

export const Title = styled.h4`
  margin-bottom: 20px;
  font-size: 22px;
  line-height: 145%;
  text-align: center;
  color: ${(props) => props.theme.colors.typography900};
`;

export const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: ${(props) => props.theme.colors.primary200};
`;

export const StyledReviewsReal = styled(ReviewsReal)`
  margin-bottom: 52px;
`;

export const StyledRating = styled(Rating)`
  margin-top: 5px;
`;

export const AddressUS = styled.p`
  max-width: 330px;
  height: 32px;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: ${(props) => props.theme.colors.typography600};
`;
