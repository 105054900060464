import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 360px;
  margin-right: auto;
  margin-left: auto;

  @media ${(props) => props.theme.breakpoints.desktop} {
    max-width: 800px;
    background: linear-gradient(180deg, #fff0f0 0%, #fffbfb 34.9%, #fffbfb 77.6%, #fff0f0 100%);
  }
`;
