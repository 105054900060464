import Address from 'core/common/components/Address/Address';
import { Locales } from 'core/user/entities';
import { Location, LocationOption } from 'core/user/ui';
import { AddressUS, Avatar, Container, StyledRating, StyledReviewsReal, Title } from './styled';

const reviewsList = [
  {
    img: <Avatar>KH</Avatar>,
    name: '@ria._.panwar',
    date: '09/17/2021',
    text:
      'It was really helpful and had provided me the clarity that I needed for my current relationship ' +
      'situation. It gives me hope that my relationship could still be save. Thank you. Highly recommended!',
    label: <StyledRating />,
  },
  {
    img: <Avatar>JP</Avatar>,
    name: '@jp63_',
    date: '09/29/2021',
    text:
      'Amazing, absolutely amazing! The affirmations I received and nurturing advice, was worth everything!' +
      ' Truly, thank you !!',
    label: <StyledRating />,
  },
  {
    img: <Avatar>TH</Avatar>,
    date: '10/02/2021',
    name: '@aarmstrong623',
    text:
      'It helps me be able to trust my self and my choices for the future by giving me reassurance with the ' +
      'information i get. My goals and dreams are going to happen and and now i trust myself to do as a need and wish',
    label: <StyledRating percent={93} />,
  },
];

const SectionFooter = () => {
  return (
    <Container>
      <Title>Users love us</Title>
      <StyledReviewsReal list={reviewsList} />
      <Location>
        <LocationOption option={Locales.US}>
          <AddressUS>Nevada, USA</AddressUS>
        </LocationOption>
        <LocationOption option={Locales.OTHER}>
          <Address />
        </LocationOption>
      </Location>
    </Container>
  );
};

export default SectionFooter;
