import styled from 'styled-components';

export const Container = styled.div`
  @media ${(props) => props.theme.breakpoints.desktop} {
    padding-right: 28px;
    padding-left: 28px;
  }
`;

export const AdvantagesList = styled.ul`
  margin-right: auto;
  margin-left: auto;
  padding: 40px 15px 10px;
  border-radius: 20px;
  background: ${(props) => props.theme.colors.themeBackgroundLight};

  @media ${(props) => props.theme.breakpoints.desktop} {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    margin-right: -6px;
    margin-left: -6px;
    padding: 40px 32px;
  }
`;

export const AdvantagesListItem = styled.li`
  padding: 12px 5px;

  @media ${(props) => props.theme.breakpoints.desktop} {
    padding: 8px 5px;
  }
`;

export const AdvantageWrapper = styled.div`
  flex: 1;
  padding: 16px;
  border-radius: 12px;
  background: ${(props) => props.theme.colors.typography100};

  @media ${(props) => props.theme.breakpoints.desktop} {
    width: 330px;
    height: 100%;
  }
`;

export const ImageWrapper = styled.div`
  & > svg {
    width: 32px;
    height: 32px;
  }
`;

export const AdvantageTitle = styled.h4`
  margin-top: 12px;
  margin-bottom: 8px;
  font-size: 18px;
  line-height: 21px;
`;

export const AdvantageSubtitle = styled.p`
  font-size: 14px;
  line-height: 21px;
`;
