import { useRouter } from 'next/router';
import { BASE_ROUTES } from 'core/common/constants';

export const useHome = () => {
  const router = useRouter();

  const startQuiz = () => {
    router.push(BASE_ROUTES.PALMISTRY);
  };

  return {
    startQuiz,
  };
};
