import SupportBotButton from 'core/common/components/SupportBotButton';
import Header from './Header';
import SectionAdvantages from './SectionAdvantages';
import SectionFooter from './SectionFooter';
import SectionHeader from './SectionHeader';
import SectionPartners from './SectionPartners';
import SectionStatistic from './SectionStatistic';
import { useHome } from './useHome';
import { Container } from './styled';

const Home = () => {
  const { startQuiz } = useHome();

  return (
    <>
      <Header buttonBack={false} />
      <SupportBotButton variant="fixed" buttonText="Help" />
      <Container>
        <SectionHeader onStart={startQuiz} />
        <SectionAdvantages />
        <SectionPartners />
        <SectionStatistic onStart={startQuiz} />
        <SectionFooter />
      </Container>
    </>
  );
};

export default Home;
