import handsAboveBall from 'core/common/assets/images/hands_above_ball.png';
import { Title, Subtitle, StyledTitle, Container, StyledButton, StyledImage } from './styled';

type SectionHeaderProps = {
  onStart: () => void;
};

const SectionHeader = ({ onStart }: SectionHeaderProps) => {
  return (
    <Container>
      <Title>Navigate life&apos;s challenges with the astrology as your guide</Title>
      <Subtitle>
        Start your self-discovery journey today by completing our quiz and receiving a tailored plan
        just for you
      </Subtitle>
      <StyledButton onClick={onStart}>Start</StyledButton>
      <StyledTitle>Step into the world of Hyper-personalized Astrology</StyledTitle>
      <StyledImage src={handsAboveBall} height={217} width={352} alt="hands above the ball" />
      <Subtitle>
        Nebula combines data from different sources to create a personalized astrological blueprint,
        while offering friendly and enjoyable guidance from professional astrologers. Its approach
        ensures personalized insights that make exploring astrology both fun and accessible.
      </Subtitle>
    </Container>
  );
};

export default SectionHeader;
