import {
  Amount,
  Container,
  ContentSubtitle,
  ContentTitle,
  ContentTitleWrapper,
  List,
  ListItem,
  ListItemContent,
  StyledButton,
  Title,
} from './styled';

export const statisticData = [
  {
    amount: '20',
    title: 'million',
    subtitle: 'happy users',
  },
  {
    amount: '93%',
    title: 'accuracy',
    subtitle: 'rated by real users',
  },
  {
    amount: '4.7/5',
    title: 'stars',
    subtitle: 'satisfaction score',
  },
  {
    amount: '300+',
    title: 'advisors',
    subtitle: 'rigorously tested',
  },
];

type SectionStatisticProps = {
  onStart: () => void;
};

const SectionStatistic = ({ onStart }: SectionStatisticProps) => {
  return (
    <Container>
      <Title>
        Step into a happier life and relationship with millions of others already benefiting from
        Nebula
      </Title>
      <List>
        {statisticData.map((data) => (
          <ListItem key={data.title}>
            <ListItemContent>
              <ContentTitleWrapper>
                <Amount>{data.amount}</Amount>
                <ContentTitle>{data.title}</ContentTitle>
              </ContentTitleWrapper>
              <ContentSubtitle>{data.subtitle}</ContentSubtitle>
            </ListItemContent>
          </ListItem>
        ))}
      </List>
      <StyledButton onClick={onStart}>Try now</StyledButton>
    </Container>
  );
};

export default SectionStatistic;
