import ButtonBack from 'core/common/components/Header/ButtonBack';
import HeaderMenu from 'core/common/components/Header/HeaderMenu';
import Icon from 'core/svg-sprites/ui/Icon';
import { ColorVariants } from 'core/theme/entities';
import { ContainerStyled } from './styled';

type HeaderProps = {
  onBack?: () => void;
  buttonBack?: boolean;
  variant?: ColorVariants;
  className?: string;
};

const Header = ({
  onBack,
  buttonBack = true,
  variant = 'primary',
  className = '',
}: HeaderProps) => (
  <ContainerStyled className={className}>
    <Icon name="nebula" width={120} height={17} />
    {buttonBack && <ButtonBack onClick={onBack} variant={variant} />}
    <HeaderMenu variant={variant} />
  </ContainerStyled>
);

export default Header;
